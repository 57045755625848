import Hero from '@namespace/top/hero'
import Gsap from '@namespace/top/gsap'
import Typing from '@namespace/top/typing'

const hero = new Hero()
hero.init()

const gsap = new Gsap()
gsap.init()

const typing = new Typing()
typing.init()
