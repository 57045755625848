import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Gsap {
  init() {
    const movePosition = window.matchMedia('(max-width: 47.99em)').matches ? -30 : -60

    gsap.to('.js-watercolor01', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-message',
        start: '0% 100%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })

    gsap.to('.js-watercolor02', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-message',
        start: '0% 100%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })

    gsap.to('.js-watercolor03', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-message',
        start: '0% 150%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })

    gsap.to('.js-watercolor04', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-seasonal-story',
        start: '0% 100%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })

    gsap.to('.js-watercolor05', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-seasonal-story',
        start: '0% 100%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })

    gsap.to('.js-watercolor06', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-news',
        start: '0% 150%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })

    gsap.to('.js-watercolor07', {
      y: movePosition,
      scrollTrigger: {
        trigger: '.top-news',
        start: '0% 100%',
        end: '100% 0%',
        scrub: 2,
        markers: false,
      },
      stagger: {
        from: 'start',
        amount: 0.2,
      },
    })
  }
}
