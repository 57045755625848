export default class Hero {
  init() {
    const slider = document.querySelectorAll('.swiper-top-main-topics')

    slider.forEach(element => {
      const length = element.querySelectorAll('.swiper-slide').length
      const fraction = document.querySelector('.js-fraction')

      if (length <= 1) {
        return true
      }

      fraction.innerHTML = `<span class="js-fraction__current">1</span> / <span class="js-fraction__maxNum"></span>`

      let maxSlideNum = 0
      let thisSlideIndex = 0
      const option = {
        spaceBetween: 0,
        slidesPerView: 1,
        loop: true,
        loopedSlides: length,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            spaceBetween: 40,
            slidesPerView: 'auto',
          },
        },
        pagination: {
          el: '.top-main-topics .swiper-pagination',
          clickable: true,
        },
        on: {
          init: function () {
            const slideNum = []
            const slideLength = document.querySelectorAll('.swiper-container .swiper-slide').length

            if (this.loopedSlides) {
              for (let i = 0; i < slideLength; i++) {
                slideNum.push(
                  document
                    .querySelector('.swiper-container')
                    .getElementsByClassName('swiper-slide')[i].dataset.swiperSlideIndex,
                )
                maxSlideNum = Math.max(...slideNum) + 1
              }
            } else {
              maxSlideNum = slideLength
            }

            document.querySelector('.js-fraction__maxNum').innerHTML = maxSlideNum
            document.querySelector('.js-fraction__current').innerHTML = 1
          },
          transitionStart: function () {
            thisSlideIndex =
              [].slice
                .call(
                  document
                    .querySelector('.swiper-pagination')
                    .querySelectorAll('.swiper-pagination-bullet'),
                )
                .indexOf(document.querySelector('.swiper-pagination-bullet-active')) + 1
            document.querySelector('.js-fraction__current').innerHTML = thisSlideIndex
          },
        },
      }

      // eslint-disable-next-line
      const swiper = new Swiper(element, option)

      window.addEventListener('resize', function () {
        swiper.updateSize()
      })
    })
  }
}
